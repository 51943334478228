import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './reducers';

import { reactReduxFirebase } from 'react-redux-firebase'
import firebase from 'firebase'

// epics
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic } from './epics';
import { SET_CURRENT_GFORCE, SET_ACCELERATION, SET_BT_ACCELERATION } from './actions';

// Initialize Firebase
var firebaseConfig = {
  apiKey: "AIzaSyCH9B4-Glk9LtcZjy3pTkk8-oHqCPewJlo",
  authDomain: "reconcraft-hud-demo.firebaseapp.com",
  databaseURL: "https://reconcraft-hud-demo.firebaseio.com",
  projectId: "reconcraft-hud-demo",
  storageBucket: "reconcraft-hud-demo.appspot.com",
  messagingSenderId: "435497534819"
};

// react-redux-firebase config
// const rrfConfig = {
//   userProfile: 'users',
//   // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
// }

// Initialize firebase instance
firebase.initializeApp(firebaseConfig)


// Initialize other services on firebase instance
// firebase.firestore() // <- needed if using firestore
// firebase.functions() // <- needed if using httpsCallable

const loggerMiddleware = createLogger({
  collapsed: true,
  predicate: (getState, action) => action.type !== '@@reactReduxFirebase/SET'
    && action.type !== SET_CURRENT_GFORCE
    && action.type !== SET_ACCELERATION
    && action.type !== SET_BT_ACCELERATION 
});

const epicMiddleware = createEpicMiddleware();

let middleware = [
  thunkMiddleware,
  epicMiddleware,
  loggerMiddleware
];

export default function configureStore(preloadedState) {
  const store = createStore(
    rootReducer,
    preloadedState,
    compose(
      applyMiddleware(...middleware),
      reactReduxFirebase(firebase, { userProfile: 'users', enableLogging: false })
    )
  );
  epicMiddleware.run(rootEpic);
  return store;
}
