import React, { Component } from 'react';
import 'typeface-roboto';
import CssBaseline from '@material-ui/core/CssBaseline';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { firebaseConnect } from 'react-redux-firebase';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { startTimer, setCurrentForce } from '../actions'
import { theme } from '../App';

class Accelerometer extends Component {

  constructor (props) {
    super(props)

    this.state = {
      x: null,
      y: null,
      z: null,
      rotation: null,
      landscape: false
    }

    this.handleAcceleration = this.handleAcceleration.bind(this)
    this.handleOrientation = this.handleOrientation.bind(this)
  }

  componentDidMount () {
    this.props.dispatch(startTimer());
    this.handleOrientation();
    window.addEventListener('devicemotion', this.handleAcceleration);
    window.addEventListener('orientationchange', this.handleOrientation);
  }

  componentWillUnmount () {
    window.removeEventListener('devicemotion', this.handleAcceleration);
    window.removeEventListener('orientationchange', this.handleOrientation);
  }

  handleOrientation (event) {
    const { orientation } = window
    this.setState({ landscape: orientation === 90 || orientation === -90 })
  }

  handleAcceleration (event) {
    const { landscape } = this.state
    // const useGravity = true;
    const acceleration = event.accelerationIncludingGravity; // useGravity ? event.accelerationIncludingGravity : event.acceleration
    const rotation = event.rotationRate || null
    const { x, y, z } = acceleration;

    this.props.dispatch(setCurrentForce({
      rotation,
      x: landscape ? y : x,
      y: landscape ? x : y,
      z: z
    }));
  }

  render() {
    const { gForce } = this.props; // , x, y, z, rotation
    return (
      <MuiThemeProvider theme={theme}>
        <div className='app'>
          <CssBaseline/>
          <Typography align='center' variant="h4" component="h3" style={{marginTop: 50}}>
            {gForce/10}
          </Typography>
          <Typography align='center'>
            G FORCE
          </Typography>
        </div>
      </MuiThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    gForce: state.force.gForce,
    x: state.force.x,
    y: state.force.y,
    z: state.force.z,
    rotation: state.force.rotation
  }
}

export default compose(
  firebaseConnect([
    'demo'
  ]),
  connect(mapStateToProps)
)(Accelerometer);
