import React, { Component } from 'react';
import 'typeface-roboto';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import BluetoothIcon from '@material-ui/icons/Bluetooth';

import { setBTAcceleration } from '../actions'
import { theme } from '../App';

const SERVICE_UUID = 'cd5c1105-4448-7db8-ae4c-d1da8cba36d0';
const CHARACTERISTIC_UUID = 'cd5c1106-4448-7db8-ae4c-d1da8cba36d0';

var bluetoothDevice;
var accelerometerCharacteristic;

class Accelerometer extends Component {

  constructor (props) {
    super(props)
    this.handleConnect = this.handleConnect.bind(this)
    this.readBtValue = this.readBtValue.bind(this);
  }

  componentDidMount () {
    setInterval(this.readBtValue, 100);
  }

  componentWillUnmount () {
    // window.removeEventListener('devicemotion', this.handleAcceleration);
    // window.removeEventListener('orientationchange', this.handleOrientation);
  }

  handleConnect() {
    // Step 1: Scan for a device with 0xffe5 service
    navigator.bluetooth.requestDevice({
      filters: [{ services: [SERVICE_UUID] }]
    })
    .then(function(device) {
      // Step 2: Connect to it
      bluetoothDevice = device;
      return bluetoothDevice.gatt.connect();
    })
    .then(function(server) {
      // Step 3: Get the Service
      return server.getPrimaryService(SERVICE_UUID);
    })
    .then(function(service) {
      // Step 4: get the Characteristic
      return service.getCharacteristic(CHARACTERISTIC_UUID);
    })
    .then(function(characteristic) {
      // Step 5: Write to the characteristic
      accelerometerCharacteristic = characteristic;
    })
    .then(value => {
      let decoder = new TextDecoder('utf-8');
      console.log('From bt: ' + decoder.decode(value));
    })
    .catch(function(error) {
       // And of course: error handling!
       console.error('Connection failed!', error);
    });
  }

  readBtValue() {
    if(accelerometerCharacteristic) {
      accelerometerCharacteristic.readValue()
      .then(value => {
        let decoder = new TextDecoder('utf-8');
        const forces = JSON.parse(decoder.decode(value));
        this.props.dispatch(setBTAcceleration(forces));
      })
      .catch(error => {
        console.log('error in bt', error);
      });
    }
  }

  render() {
    return (
      <Button variant="contained" color="primary" className={theme.button} onClick={this.handleConnect}>
        <BluetoothIcon/>
        Connect
      </Button>
    );
  }
}

export default connect()(Accelerometer);
