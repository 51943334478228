import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

// import ShipImg from './Ship.svg';
import Boat from './Boat/boat.obj';
import BoatMtl from './Boat/boat.mtl';

import { OBJModel, DirectionLight, Tick } from 'react-3d-viewer'

const styles = theme => ({
  root: {
    flexGrow: 1,
    position: 'absolute',
    top: '20px',
    bottom: '0',
    left: '40%',
    right: '40%',
    width: '0',
    opacity: 0.1
  }
});

class Ship extends Component {

  constructor (props) {
    super(props)

    this.state = {
      rotation: {
        x: 0,
        y: 0,
        z: 0
      }
    }
  }

  componentDidMount(){
    // this.tick = Tick(()=>{
    //   var {rotation} = this.state
    //   rotation.y += 0.005
    //   this.setState({rotation})
    // })
  }

  render() {
    const { classes, gyro, rotation } = this.props;

    return (
      <div className={classes.root}>
        <OBJModel
          src={Boat}
          mtl={BoatMtl}
          position={{x:0,y:0,z:0}}
          rotation={gyro}
          texPath={'./Boat/Texture/'}
          background={'transparent'}
        >
          <DirectionLight color={0xffffff}/>
        </OBJModel>
      </div>
    );
  }

}

Ship.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const force = state.force;
  return {
    gyro: force.gyro
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Ship));
