import { combineEpics, ofType } from 'redux-observable';
import { map, tap, mapTo, takeUntil, switchMap, filter } from 'rxjs/operators'; // ,debounceTime , filter
import { getFirebase } from 'react-redux-firebase'
import { setCurrentGForce, setAcceleration, START_TIMER, readBluetooth } from './actions'; // ,
import { timer } from 'rxjs';

const SAMPLE_RATE = 100;
const BACKOFF = 5;

export function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

export function getRandomGForce(currentGForce) {
  const rand = getRandomInt(100);
  const backoff = currentGForce - BACKOFF;

  if(rand > backoff) {
    if(rand > 80) {
      return Math.max(backoff, getRandomInt(95));
    }
  }
  return Math.max(backoff, 1);
}


// const timerEpic = (action$, state$) =>
//   action$.pipe(
//     ofType(START_TIMER),
//     switchMap(() =>
//       timer(0, SAMPLE_RATE)
//         .pipe(
//           map(() => setCurrentGForce(getRandomGForce(state$.value.force.gForce))),
//           takeUntil(action$.ofType('STOP_TIMER'))
//         )
//     )
//   );


const getForceFromFirebase = (action$, state$) =>
  action$.pipe(
    ofType('@@reactReduxFirebase/SET'),
    map(action => setAcceleration(action.data)),
    //map(action => setCurrentGForce(Math.floor(action.data.gForce * 10)))
  );

const setForceInFirebase = (action$, state$) =>
  action$.pipe(
    ofType(START_TIMER),
    switchMap(() =>
      timer(0, SAMPLE_RATE)
        .pipe(
          filter(() => state$.value.force.chip.gForce > 0),
          tap(() => getFirebase().update('demo', {
            gForce: state$.value.force.chip.gForce,
            x: state$.value.force.chip.x,
            y: state$.value.force.chip.y,
            z: state$.value.force.chip.z,
            rotation: state$.value.force.chip.rotation
          })),
          takeUntil(action$.ofType('STOP_TIMER')),
          mapTo({type: 'FIREBASE_UPDATED'}),
        )
    )
  );

// const setForceInFirebase = (action$, state$) =>
//   action$.pipe(
//     ofType('SET_ACCELERATION'),
//     debounceTime(2000),
//     tap(action => getFirebase().update('demo', action)),
//     mapTo({type: 'FIREBASE_UPDATED'})
//   );

export const rootEpic = (...args) => combineEpics(
  getForceFromFirebase,
  setForceInFirebase,
  // timerEpic
)(...args, getFirebase);
