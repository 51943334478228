export const START_TIMER = 'START_TIMER';
export const SET_CURRENT_GFORCE = 'SET_CURRENT_GFORCE';
export const SET_ACCELERATION = 'SET_ACCELERATION';
export const SET_CHIP_FORCE = 'SET_CHIP_FORCE';
export const SET_BT_ACCELERATION = 'SET_BT_ACCELERATION';

let bluetoothCharacteristic;

export function startTimer() {
  return {
    type: START_TIMER
  }
}

export function setCurrentGForce(gForce = 0.0) {
  return {
    type: SET_CURRENT_GFORCE,
    gForce: gForce
  };
}

export function setAcceleration(acceleration) {
  return {
    type: SET_ACCELERATION,
    ...acceleration
  };
}

export function setBTAcceleration(acceleration) {
  if(!acceleration.gForce) {
    const { x, y, z } = acceleration.accel;
    acceleration.gForce = Math.sqrt(x * x + y * y + z * z);
  }
  return {
    type: SET_BT_ACCELERATION,
    ...acceleration
  };
}

export function setCurrentForce(force) {
  const { x, y, z } = force;
  return {
    type: SET_CHIP_FORCE,
    ...force,
    gForce: Math.sqrt(x * x + y * y + z * z) // / 9.8
  };
}

export function readBluetooth() {
  if(!bluetoothCharacteristic) {
    console.log('no bt yet');
    return {
      type: 'BOO_NO'
    };
  }
  return (dispatch) => {
    return bluetoothCharacteristic.readValue()
    .then(value => {
      console.log(value);
      return {
        type: 'BOO_YA'
      }
    })
  }
}
