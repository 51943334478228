import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

// Like https://github.com/brunobertolini/styled-by
// const styledBy = (property, mapping) => props => mapping[props[property]];

const styles = theme => ({
  sidebarItem: {
    textTransform: 'uppercase'
  },
});

function SidebarRaw(props) {
  const { classes, align, color, header, children, ...other } = props;
  return (
    <div className={classes.sidebarItem} {...other}>
      <Typography align={align} color='textSecondary'>
        {header}
      </Typography>
      <Typography align={align} variant="h4" component="h3">
        {children.toFixed ? (children).toFixed(1).toLocaleString() : children}
      </Typography>
    </div>
  );
}

SidebarRaw.propTypes = {
  classes: PropTypes.object.isRequired,
  align: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
};

export default withStyles(styles)(SidebarRaw);
