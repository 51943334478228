import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { VictoryPie } from 'victory';

const MAX_CUMULATIVE = 100000;

const styles = theme => ({
  root: {
    flexGrow: 1,
    position: 'absolute',
    top: '22%',
    bottom: '22%',
    left: '22%',
    right: '22%',
    opacity: 0.8
  }
});

const colors = {
  green: '#169F00',
  gray: 'rgba(255,255,255,0.05)'
}

function Cumulative(props) {
  const { classes, cumulativeGForce } = props;

  return (
    <div className={classes.root}>
      <VictoryPie
        colorScale={[colors.green, colors.gray]}
        startAngle={-140}
        endAngle={140}
        innerRadius={120}
        padAngle={1}
        labels={(d) => ''}
        data={[
          { y: cumulativeGForce },
          { y: MAX_CUMULATIVE - cumulativeGForce }
        ]}
      />
    </div>
  );
}

Cumulative.propTypes = {
  classes: PropTypes.object.isRequired,
  cumulativeGForce: PropTypes.number.isRequired,
};

export default withStyles(styles)(Cumulative);
