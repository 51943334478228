import { combineReducers } from 'redux';
import {
  SET_CURRENT_GFORCE,
  SET_ACCELERATION,
  SET_CHIP_FORCE,
  SET_BT_ACCELERATION
} from './actions';

function force(state = {
  gForce: 0,
  cumulativeGForce: 0,
  meanGForce: 0,
  maxGForce: 0,
  samples: 0,
  x: 0,
  y: 0,
  z: 0,
  gyro: {
    x: 0,
    y: 0,
    z: 0
  },
  rotation: {},
  calculatedGForce: 0,
  warning: false,
  alert: false,
  person: false,
  chip: {
    x: 0,
    y: 0,
    z: 0,
    rotation: {}
  }
}, action) {
  let newState;
  switch (action.type) {
    case SET_CURRENT_GFORCE:
      newState = {...state};
      newState.samples = newState.samples + 1;
      newState.gForce = action.gForce;
      newState.cumulativeGForce = Math.floor(newState.cumulativeGForce + (action.gForce/10));
      newState.maxGForce = Math.max(newState.maxGForce, action.gForce);
      newState.meanGForce = newState.cumulativeGForce / newState.samples;

      // set warning indicators
      if(newState.maxGForce > 80) {
        newState.warning = true;
      }
      if(newState.mean > 40) {
        newState.alert = true;
      }
      if(newState.cumulativeGForce > 1000) {
        newState.person = true;
      }

      return newState;
    case SET_ACCELERATION:
      newState = {...state};
      newState.x = action.x;
      newState.y = action.y;
      newState.z = action.z;
      newState.rotation = action.rotation;
      newState.gForce = action.gForce;
      return newState;
    case SET_BT_ACCELERATION:
      newState = {...state};
      newState.x = action.accel.x;
      newState.y = action.accel.y;
      newState.z = action.accel.z;
      //newState.gyro.x += action.gyro.x/10;
      //newState.gyro.y += action.gyro.y/10;
      newState.gyro.z += action.gyro.z/10;
      newState.gForce = action.gForce;
      return newState;
    case SET_CHIP_FORCE:
      newState = {...state};
      newState.chip.x = action.x;
      newState.chip.y = action.y;
      newState.chip.z = action.z;
      newState.chip.rotation = action.rotation;
      newState.chip.gForce = action.gForce;
      return newState;
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  force
});

export default rootReducer;
