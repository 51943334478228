import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { VictoryPie } from 'victory';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    flexGrow: 1,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0.8
  },
  gForce: {
    position: 'absolute',
    width: 120,
    height: 50,
    top: '50%',
    left: '50%',
    marginLeft: -60, /* margin is -0.5 * dimension */
    marginTop: -25,
  }
});

const colors = {
  green: '#169F00',
  yellow: '#FFC704',
  red: '#B20000',
  gray: 'rgba(255,255,255,0.05)'
}

const colorScale = [
  colors.green,
  colors.green,
  colors.green,
  colors.green,
  colors.green,
  colors.green,
  colors.green,
  colors.green,
  colors.green,
  colors.green,
  colors.green,
  colors.green,
  colors.green,
  colors.green,
  colors.green,
  colors.green,
  colors.green,
  colors.yellow,
  colors.yellow,
  colors.yellow,
  colors.yellow,
  colors.yellow,
  colors.yellow,
  colors.yellow,
  colors.yellow,
  colors.red,
  colors.red,
  colors.red,
  colors.red,
  colors.red,
];

const wedgeData = colorScale.map(() => ({y: 10}));

function getColorScale(gForce) {
  const cutoff = Math.ceil(colorScale.length * gForce/100);
  return [...colorScale.slice(0, cutoff), ...colorScale.slice(cutoff).map(() => colors.gray)]
}

function Current(props) {
  const { classes, gForce } = props;

  const wedgeColors = getColorScale(gForce);
  return (
    <div className={classes.root}>
      <VictoryPie
        colorScale={wedgeColors}
        startAngle={-140}
        endAngle={140}
        innerRadius={90}
        padAngle={1}
        labels={(d) => ''}
        data={wedgeData}
      />
      <div className={classes.gForce}>
        <Typography variant='h3' align='right'>{_.round(gForce/10, 1)} G</Typography>
      </div>
    </div>
  );
}

Current.propTypes = {
  classes: PropTypes.object.isRequired,
  gForce: PropTypes.number.isRequired,
};

export default withStyles(styles)(Current);
