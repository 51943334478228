import React, { Component } from 'react';
import 'typeface-roboto';
import CssBaseline from '@material-ui/core/CssBaseline';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { firebaseConnect } from 'react-redux-firebase';
import { startTimer } from './actions'
import Dashboard from './hud/dashboard';
import './App.css';

export const theme = createMuiTheme({
  app: {
    padding: 200,
  },
  palette: {
    type: 'dark',
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#1f426c',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#89da00',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // error: will use the default color
  },
  typography: {
    body1: {
      textTransform: 'uppercase'
    }
  }
});

class App extends Component {

  componentDidMount () {
    this.props.dispatch(startTimer());
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <div className='app'>
          <CssBaseline/>
          <Dashboard/>
        </div>
      </MuiThemeProvider>
    );
  }

}

export default compose(
  firebaseConnect([
    'demo'
  ]),
  connect()
)(App);
