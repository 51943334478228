import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DashboardComponent from './dashboard';

function Dashboard(props) {
  const { gForce, cumulativeGForce, maxGForce, meanGForce, warning, alert, person } = props;

  return (
    <DashboardComponent
      gForce={gForce}
      cumulativeGForce={cumulativeGForce}
      maxGForce={maxGForce}
      meanGForce={meanGForce}
      warning={warning}
      alert={alert}
      person={person}
    />
  );
}

Dashboard.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    gForce: state.force.gForce,
    cumulativeGForce: state.force.cumulativeGForce,
    maxGForce: state.force.maxGForce,
    meanGForce: state.force.meanGForce,
    warning: state.force.warning,
    alert: state.force.alert,
    person: state.force.person
  }
}

export default connect(mapStateToProps)(Dashboard);
