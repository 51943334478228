import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import App from './App';
import Accelerometer from './accelerometer';

const AppRouter = () => (
  <Router>
    <div>
      <Route path="/" exact component={App} />
      <Route path="/sensor" exact component={Accelerometer} />
    </div>
  </Router>
);

export default AppRouter;
