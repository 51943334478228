import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SidebarItem from '../sidebarItem';
import Current from '../gForce/current';
import Cumulative from '../gForce/cumulative';
import Typography from '@material-ui/core/Typography';
import { Lock, Warning, NotificationImportant, Person } from '@material-ui/icons';
import Moment from 'react-moment';
import Ship from '../vehicles/ship';
import ConnectBluetooth from '../../bluetooth';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  icon: {
    marginLeft: theme.spacing.unit,
    fontSize: 64,
    //color: '#333333'
  },
  lockIcon: {
    fontSize: 16,
    color: '#89da00'
  },
});

// <Ship/>

function Dashboard(props) {
  const { classes, gForce, meanGForce, maxGForce, cumulativeGForce, warning, alert, person } = props;

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={24}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={12}>
          <Current gForce={gForce}/>
          <Cumulative cumulativeGForce={cumulativeGForce}/>
        </Grid>
        <Grid item xs={6} sm={6}>
          <div align='left'>
            <div><Lock className={classes.lockIcon}/> 256-BIT ENCRYPTION</div>
            <Typography color='textSecondary'>
              <Moment format='dddd MMMM D'/>
            </Typography>
            <Typography color='textSecondary'>
              <Moment format='HH:mm'/>
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6} sm={6}>
          <div align='right'>
            <Warning className={classes.icon} color={warning ? 'error' : 'disabled'}/>
            <NotificationImportant className={classes.icon} color={alert ? 'error' : 'disabled'}/>
            <Person className={classes.icon} color={person ? 'error' : 'disabled'}/>
          </div>
        </Grid>
        <Grid item xs={12} sm={12}/>
        <Grid item xs={6} sm={6}>
          <SidebarItem align='left' header='Link'>Wired</SidebarItem>
        </Grid>
        <Grid item xs={6} sm={6}>
          <SidebarItem align='right' header='Cumulative Impact'>{cumulativeGForce}</SidebarItem>
        </Grid>
        <Grid item xs={6} sm={6}>
          <SidebarItem align='left' header='Mean G-Force'>{meanGForce}</SidebarItem>
        </Grid>
        <Grid item xs={6} sm={6}>
          <SidebarItem align='right' header='Max G-Force'>{maxGForce/10}</SidebarItem>
        </Grid>
        <Grid item xs={6} sm={6}>
          <SidebarItem align='left' header='Station'>Bow</SidebarItem>
        </Grid>
        <Grid item xs={6} sm={6}>
          <SidebarItem align='right' header='Data Logger'>On</SidebarItem>
        </Grid>
        <Grid item xs={12} sm={12} align='left'>
          <ConnectBluetooth/>
        </Grid>
      </Grid>
    </div>
  );
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  gForce: PropTypes.number.isRequired,
  cumulativeGForce: PropTypes.number.isRequired,
  meanGForce: PropTypes.number.isRequired,
  maxGForce: PropTypes.number.isRequired,
  warning: PropTypes.bool.isRequired,
  alert: PropTypes.bool.isRequired,
  person: PropTypes.bool.isRequired
};

export default withStyles(styles)(Dashboard);
